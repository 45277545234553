import React from 'react';

const Phone = () => (
  <div className="inline-container">
    <a href="tel:7807587212">
      780.758.7212
    </a>
  </div>
);

export default Phone;
