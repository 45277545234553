import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.png`} alt="" />
      </Link>
      <header>
        <h3>Shelley Miller, K.C.</h3>
        <p>Insurance law and<br />risk management specialist</p>
      </header>
    </section>

    <section className="blurb">
      <p>
        Specialties in insurance law reform, regulatory insight and advocacy,
        advice and arbitration for associations,
        governments and groups, discipline panel chair expertise.
      </p>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; 2023 Shelley Miller, K.C.</p>
    </section>
  </section>
);

export default SideBar;
