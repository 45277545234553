const routes = [
  {
    index: true,
    label: 'Shelley Miller, K.C.',
    path: '/',
  },
  {
    label: 'About',
    path: '/about',
  },
  {
    label: 'Expertise',
    path: '/expertise',
  },
  {
    label: 'Boards and Affiliations',
    path: '/affiliations',
  },
  {
    label: 'Publications',
    path: '/publications',
  },
  {
    label: 'Contact',
    path: '/contact',
  },
];

export default routes;
